<template>
  <div class="container_con">
    <el-card>
      <div class="con_title"><span></span> 新增采购计划</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="160px">
        <div class="flex">
          <div>
            <el-form-item label="开票日期：" prop="purchase_plan_date">
              <el-date-picker v-model="form.purchase_plan_date" type="date" value-format="YYYY-MM-DD" placeholder="选择日期"
                style="width:300px">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="部门：" prop="department_id">
              <el-cascader style="width:300px" v-model="form.department_id" :options="list"
                :props="{ expandTrigger: 'hover', checkStrictly: true, emitPath: false, value: 'id', label: 'title', children: '_child' }"
                clearable></el-cascader>
            </el-form-item>
            <el-form-item label="采购员：" prop="admin_id">
              <el-select v-model="form.admin_id" placeholder="请选择" style="width: 100%;; margin-right: 15px;" filterable>
                <el-option :label="item.full_name + '(' + item.department_name + ')' + '-' + item.mnemonic_code"
                  v-for="(item, index) in cglist" :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item :label="!form.id ? '单据编号前缀：' : '单据编号：'" prop="purchase_plan_no">
              <el-select v-model="form.purchase_plan_no" v-if="!form.id" placeholder="请选择"
                style="width: 100%;; margin-right: 15px;" filterable>
                <el-option :label="item.value + '__' + item.title" v-for="(item, index) in zjlist" :key="index"
                  :value="item.value"></el-option>
              </el-select>
              <el-input v-if="form.id" disabled style="width: 100%;" v-model="form.purchase_plan_no"
                placeholder="请输入单据编号" />
            </el-form-item>

            <el-form-item label="供货单位：" prop="merchant_id">
              <el-select v-model="form.merchant_id" placeholder="请选择" @change="get_price()"
                style="width: 100%;; margin-right: 15px;" filterable ref="kehu">
                <el-option :label="item.merchant_name + '-' + item.merchant_no + '-' + item.mnemonic_code"
                  v-for="(item, index) in dwlist" :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="备注：" prop="remark">
              <el-input v-model="form.remark" placeholder="请输入备注" style="width:100%" />
            </el-form-item>
          </div>
        </div>

        <div class="table_Box">

          <el-table :data="have_sele_arr" ref="tables" style="width: 100%" class="custom-table"
            @sort-change="handleSortChange">
            <el-table-column type="index" width="50">
            </el-table-column>
            <el-table-column prop="product_no" label="产品编号" width="120" sortable :sort-method="formatterproduct_no" />
            <el-table-column prop="product_name" label="品名" width="180" sortable :sort-method="formatterproduct_name" />
            <el-table-column prop="model" label="型号规格" />
            <el-table-column prop="pack_unit_name" label="包装单位" width="100" />
            <el-table-column prop="manufacturer" label="生产厂家" width="150" />
            <el-table-column label="数量" width="150" sortable :sort-method="formatterproduct_num">
              <template v-slot="scope">
                <div>
                  <el-input-number v-model="scope.row.product_num" controls-position="right" :min="0"
                    size="small"></el-input-number>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="单价" width="200" sortable :sort-method="formatterproduct_price">
              <template v-slot="scope">
                <div>
                  <el-input-number v-model="scope.row.product_price" controls-position="right" :min="0" :step="0.001"
                    size="small" style="width:150px"></el-input-number> 元
                </div>
              </template>
            </el-table-column>
            <el-table-column label="金额" sortable :sort-method="formatteramount">
              <template v-slot="scope">
                <div style="color: #FF5115;">
                  {{ (scope.row.product_num * scope.row.product_price || 0).toFixed(3) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="stock" label="库存" />
            <el-table-column label="通知业务员" width="200">
              <template v-slot="scope">
                <div>
                  <el-select v-model="scope.row.salesman_id" placeholder="请选择" style="width: 100%; margin-right: 15px;"
                    filterable clearable>
                    <el-option :label="item.full_name + '(' + item.department_name + ')' + '-' + item.mnemonic_code"
                      v-for="(item, index) in ywlist" :key="index" :value="item.id"></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="purchase_price" label="参考进价" />
            <el-table-column fixed="right" label="操作" width="120">
              <template v-slot="scope">
                <el-button type="text" size="small" @click="handleDelete(scope.$index)">删除</el-button>
                <el-button type="text" size="small" @click="contextmenu(scope.row)">产品账页</el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="table_title flex_ac" style="padding-bottom: 20px;">
            采购产品： <span @click="sele_products">选择产品</span>
            <div class="t_money margin_a">合计金额: <b style="color: red; font: 16px;">{{ get_all(have_sele_arr,
              'product_num',
              'product_price') }}</b></div>
          </div>
        </div>

        <div class="con_title"><span></span> 审批信息</div>

        <div class="flex_ac" v-for="(item2, index2) in sh_arr" :key="index2">
          <el-form-item :label="item2.title + '意见：'" style="width:400px">
            <el-select v-model="item2.opinion" placeholder="请选择意见" style="width:100%">
              <el-option label="同意" value="1"></el-option>
              <el-option label="不同意" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="经办人:" style="width:400px">
            <el-select v-model="item2.admin_id" placeholder="请选择" style="width: width:100% " filterable>
              <el-option :label="item.full_name + '(' + item.department_name + ')'" v-for="(item, index) in rylist"
                v-show="item['is_' + item2.key] == 1" :key="index" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </div>


      </el-form>
    </el-card>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.push('/purchasePlan')">取消</el-button>
  </div>
  <selectProducts ref="selectProducts" @after_sele="after_sele_Products" :fromUrl="'/addpurchasePlan'" />
  <salesput ref="salesput" />
</template>

<script>
import salesput from "@/components/sales_put";
export default {
  name: "addpurchasePlan",
  components: { salesput },
  data() {
    return {
      id: '',
      is_ajax: false,
      form: {

      },
      rules: {
        purchase_plan_date: [
          { required: true, message: "开票日期不能为空", trigger: "blur" }
        ],
        purchase_plan_no: [
          { required: true, message: "编号前缀不能为空", trigger: "blur" }
        ],
        department_id: [
          { required: true, message: "部门不能为空", trigger: "blur" }
        ],
        admin_id: [
          { required: true, message: "采购员不能为空", trigger: "blur" }
        ],
        merchant_id: [
          { required: true, message: "供货单位不能为空", trigger: "blur" }
        ]
      },
      zjlist: [],
      list: [],
      rylist: [],
      dwlist: [],
      cglist: [],
      ywlist: [],
      sh_arr: [
        { admin_id: '', opinion: '1', title: '业务部', key: 'salesman' },
        { admin_id: '', opinion: '1', title: '质管部', key: 'quality' },
        { admin_id: '', opinion: '1', title: '销售部', key: 'sale' },
        { admin_id: '', opinion: '1', title: '总经理', key: 'president' }
      ],
      // 已选择产品
      have_sele_arr: [],
      sortedData: [],
      sortConditions: [], // 存储排序条件的数组  
      keyDownListener: null,
    };
  },
  created() {
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.get_data()
    } else {
      this.form.purchase_plan_date = this.today_val()
    }
    this.getOptions()
  },
  mounted() {

  },
  computed: {
    newData() {
      return this.$store.state.isKeyDownListenerAdded
    },
  },
  watch: {
    newData(newval, oldval) {
      if (newval) {
        this.sele_products();
        this.$refs.kehu.blur();
      }
    }
  },
  methods: {
    handleSortChange({ column, prop, order }) {
      // 获取排序后的数组
      const sortedData = this.$refs.tables.store.states.data;
      this.sortedData = sortedData
    },
    formatterproduct_name(a, b) {
      return a.product_name.localeCompare(b.product_name)
    },
    formatterproduct_no(a, b) {
      return a.product_no.localeCompare(b.product_no)
    },
    formatteramount(a, b) {
      return Number((a.product_num * a.product_price || 0).toFixed(3)) - Number((b.product_num * b.product_price || 0).toFixed(3));
    },
    formatterproduct_price(a, b) {
      return Number(a.product_price) - Number(b.product_price);
    },
    formatterproduct_num(a, b) {
      return Number(a.product_num) - Number(b.product_num);
    },
    get_all(arr, k1, k2) {
      let n = 0;
      for (let i = 0; i < arr.length; i++) {
        n += (arr[i][k1] || 0) * (arr[i][k2] || 0)
      }
      return n.toFixed(4)
    },
    contextmenu(row, column, cell, e) {
      // console.log(row)
      this.$refs.salesput.init(row.id);
      if (e) {
        e.preventDefault();
      }
      return false;
    },
    get_price(id) {
      let all_ids = [];
      for (let i = 0; i < this.have_sele_arr.length; i++) {
        if (all_ids.indexOf(this.have_sele_arr[i].id) == -1) {
          all_ids.push(this.have_sele_arr[i].id)
        }
      }
      for (let i = 0; i < this.have_sele_arr.length; i++) {
        for (let j = 0; j < id.length; j++) {
          if (this.have_sele_arr[i].id == id[j] && this.have_sele_arr[i].product_price != undefined) {
            id.splice(j, 1)
          }
        }
      }
      let ids = id || all_ids;
      console.log(ids);
      if (this.form.merchant_id && ids.length > 0) {
        for (let i = 0; i < ids.length; i++) {
          this.get_item_price(ids[i])
        }
      }
    },
    get_item_price(id) {
      this.$httpGet("/backend/PurchasePlan/purchasePlanPrice", { product_id: id, merchant_id: this.form.merchant_id }).then((res) => {
        if (res.status == 200) {
          for (let i = 0; i < this.have_sele_arr.length; i++) {
            if (this.have_sele_arr[i].id == id) {
              this.have_sele_arr[i].product_price = res.data || undefined
            }
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    },

    /** 删除按钮操作 */
    handleDelete(index) {
      const _this = this;
      this.$confirm('是否确认删除?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this.have_sele_arr.splice(index, 1)
      })
    },
    // 选择产品
    after_sele_Products(arr) {
      let ids = [];
      for (let i = 0; i < arr.length; i++) {
        ids.push(arr[i].id)
      }
      this.get_price(ids)
      this.have_sele_arr = this.have_sele_arr.concat(arr);
      this.set_default_man();
    },
    // 默认设置第一个
    set_default_man() {
      // if((this.ywlist || []).length>0){
      //     for(let i=0;i<this.have_sele_arr.length;i++){
      //         this.have_sele_arr[i].salesman_id=this.ywlist[0].id
      //     }
      // }
    },
    sele_products() {
      if (!this.form.merchant_id) {
        this.$message.error('请先选择供货单位');
        return false;
      }
      let ids = []; // 已选择的id
      for (let i = 0; i < this.have_sele_arr.length; i++) {
        ids.push(this.have_sele_arr[i].id)
      }
      this.$refs['selectProducts'].init([])
    },

    // 表单重置
    reset() {
      this.form = {
        purchase_plan_date: this.today_val(),
        purchase_plan_no: this.zjlist.length > 0 ? this.zjlist[0].value : ''
      };
      this.sh_arr = [
        { admin_id: '', opinion: '1', title: '业务部', key: 'salesman' },
        { admin_id: '', opinion: '1', title: '质管部', key: 'quality' },
        { admin_id: '', opinion: '1', title: '销售部', key: 'sale' },
        { admin_id: '', opinion: '1', title: '总经理', key: 'president' }
      ]
      this.have_sele_arr = [];
      if (this.$refs['form']) {
        this.$refs['form'].clearValidate();
      }
    },
    /** 提交按钮 */
    submitForm: function (form) {
      this.$refs[form].validate((valid) => {
        if (valid) {

          if (form == 'form') {
            let _data = { ...this.form };

            let proarr = [];
            if (this.have_sele_arr.length == 0) {
              this.$message.error('请选择产品');
              return false;
            }
            if (this.sortedData.length == 0) { //没排序的
              for (let i = 0; i < this.have_sele_arr.length; i++) {
                let _item = this.have_sele_arr[i];
                if (!_item.product_num || (!_item.product_price && _item.product_price != 0)) {
                  this.$message.error('请完善产品数量、价格');
                  return false;
                } else {
                  proarr.push({
                    product_id: _item.id,
                    product_num: _item.product_num,
                    product_price: _item.product_price,
                    amount: parseFloat((_item.product_price * _item.product_num).toFixed(3) || 0),
                    salesman_id: _item.salesman_id
                  })
                }
              }
            } else { //排序后的顺序
              for (let i = 0; i < this.sortedData.length; i++) {
                let _item = this.sortedData[i];
                if (!_item.product_num || (!_item.product_price && _item.product_price != 0)) {
                  this.$message.error('请完善产品数量、价格');
                  return false;
                } else {
                  proarr.push({
                    product_id: _item.id,
                    product_num: _item.product_num,
                    product_price: _item.product_price,
                    amount: parseFloat((_item.product_price * _item.product_num).toFixed(3) || 0),
                    salesman_id: _item.salesman_id
                  })
                }
              }
            }

            let arr = [];
            for (let i = 0; i < this.sh_arr.length; i++) {
              if (!this.sh_arr[i].admin_id) {
                this.$message.error('请选择' + this.sh_arr[i].title + '经办人');
                return false;
              }
            }


            // 提交
            this.is_ajax = true;
            _data.audit_array = JSON.stringify(this.sh_arr);
            _data.product_array = JSON.stringify(proarr);
            this.$httpPost("/backend/PurchasePlan/save", _data).then((res) => {
              if (res.status == 200) {
                this.$message.success(this.id ? '更新成功' : '新增成功');
                // if(this.id){
                window.is_need_back = true;
                this.$router.go(-1)
                // }else{
                //     this.$nextTick(()=>{
                //         this.reset();
                //     })
                // }
              } else {
                this.$message.error(res.message);
              }
              this.is_ajax = false;
            }).catch((err) => {
              console.log(err);
              this.is_ajax = false;
            });
          }
        }
      });
    },
    get_data() {
      this.$httpGet("/backend/PurchasePlan/read", { id: this.id }).then((res) => {
        if (res.status == 200) {
          let _data = res.data;
          let proarr = [];
          for (let i = 0; i < _data.purchase_plan_product.length; i++) {
            let _item = _data.purchase_plan_product[i];
            let _pro = _item.product;
            _pro.product_num = _item.product_num;
            _pro.product_price = parseFloat(_item.product_price);
            _pro.salesman_id = _item.salesman_id || '';
            _pro.product_num = _item.product_num;
            _pro.pack_unit_name = _item.pack_unit_name;
            _pro.model = _item.model;
            proarr.push(_pro)
          }
          this.have_sele_arr = proarr;

          for (let i = 0; i < _data.purchase_plan_audit.length; i++) {
            this.sh_arr[i].admin_id = _data.purchase_plan_audit[i].admin_id;
            this.sh_arr[i].opinion = _data.purchase_plan_audit[i].opinion.toString()
          }
          this.form = res.data
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    getOptions() {
      this.$httpGet("/backend/SysConfig/index", {
        size: 10000000,
        groups: '采购计划'
      }).then((res) => {
        if (res.status == 200) {
          this.zjlist = res.data.data;
          if (!this.id && this.zjlist.length > 0) {
            this.form.purchase_plan_no = this.zjlist[0].value
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });

      this.$httpGet("/backend/sys.Admin/assistData", {}).then((res) => {
        if (res.status == 200) {
          this.list = res.data.department_list;
          if (!this.id) {
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].title == '采购部') {
                this.form.department_id = this.list[i].id
                break;
              }
            }
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });

      // 获取采购员 cglist
      this.$httpGet("/backend/sys.Admin/purchaseList", { size: 10000000 }).then((res) => {
        if (res.status == 200) {
          this.cglist = res.data.data || [];
          // if(!this.id && this.cglist.length>0){
          //     this.form.admin_id=this.cglist[0].id
          // }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
      // 获取业务员 ywlist
      this.$httpGet("/backend/sys.Admin/salesmanList", { size: 10000000 }).then((res) => {
        if (res.status == 200) {
          this.ywlist = res.data.data;
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });

      // 获取经办人
      this.$httpGet("/backend/sys.Admin/handlerList", { size: 10000000 }).then((res) => {
        if (res.status == 200) {
          this.rylist = res.data;

          // 默认选中第一个
          if (!this.id && this.rylist.length > 0) {
            for (let i = 0; i < this.sh_arr.length; i++) {
              if (this.sh_arr[i].admin_id == '') {
                for (let k = 0; k < this.rylist.length; k++) {
                  if (this.rylist[k]['is_' + this.sh_arr[i].key] == '1') {
                    this.sh_arr[i].admin_id = this.rylist[k].id;
                    break;
                  }
                }
              }
            }
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });


      this.$httpGet("/backend/Merchant/merchant_purchase", { size: 10000000 }).then((res) => {
        if (res.status == 200) {
          this.dwlist = res.data.data;
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.table_Box {
  padding-left: 50px;
  font-size: 14px;

  .table_title {
    span {
      color: #1676e0;
      cursor: pointer;
    }
  }

  .el-table {
    margin: 30px 0;
  }
}

.top_box {
  display: flex;
  justify-content: space-around;
}

.examin {
  display: flex;
  justify-content: space-between;
}

/* 选中某行时的背景色*/
/deep/.custom-table tr.current-row>td {
  background-color: #92cbf1 !important;
}

/*鼠标移入某行时的背景色*/
/deep/.custom-table .el-table__body tr:hover>td {
  background-color: rgba(195, 198, 202, 0.5) !important;
  ;
}

/deep/.custom-table .el-table__body tr:hover>td>.cell {
  font-weight: 600;
}
</style>
